<script lang="ts" setup>
const { open } = useAppModals();
const isGuest = useIsGuest();
const { isActiveMiniWheel, isPercentEnabled } = useDepositStreakData({ immediate: !isGuest.value });
</script>

<template>
	<div v-if="isActiveMiniWheel" class="wheel-mini" @click="open('LazyOModalWheelOfTreasurePlayWheel')">
		<LazyMWheelOfTreasureFakeWheel :wheelSize="35" :isPercentEnabled="isPercentEnabled" clearSectors />
	</div>
</template>

<style lang="scss" scoped>
.wheel-mini {
	position: fixed;
	bottom: 90px;
	right: 0;
	background: var(--changshu);
	padding: 10px;
	border-radius: 8px 0 0 8px;
	box-shadow: 0 0 12px 0 var(--caracas);
	backdrop-filter: blur(10px);
	z-index: 2;
}
</style>
